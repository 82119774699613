#gridCategoriasMapa div div .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px; 
    font-size: 12px;
}

#gridCategoriasMapa div div .MuiAutocomplete-option[class*="MuiOutlinedInput-root"] {
    padding: 0px;     
    font-size: 12px;
}

#gridDistanciaMapa div div .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px; 
    height:34px;
    font-size: 12px;
}

#gridDistanciaMapa div div .MuiAutocomplete-option[class*="MuiOutlinedInput-root"] {
    padding: 0px; 
    height:34px;
    font-size: 12px;
}


#gridDistanciaMapa div div .MuiAutocomplete-tag[data-tag-index='0'] {    
    visibility: visible !important;
}

#gridDistanciaMapa div div .MuiAutocomplete-tag[data-tag-index] {    
    visibility: collapse;
}


div .MuiAutocomplete-listbox { 
    font-size: 12px;
    padding-left: 1px;
}

#lineaCargando div .MuiLinearProgress-colorSecondary {
    background-color: #b14fc5;    
}
 
#lineaCargando div .MuiLinearProgress-barColorSecondary  {
    background-color: #6a2f79;    
}
